
:host,:root,[data-theme] {
  --chakra-ring-inset: var(--chakra-empty,/*!*/ /*!*/);
  --chakra-ring-offset-width: 0px;
  --chakra-ring-offset-color: #fff;
  --chakra-ring-color: rgba(66, 153, 225, 0.6);
  --chakra-ring-offset-shadow: 0 0 #0000;
  --chakra-ring-shadow: 0 0 #0000;
  --chakra-space-x-reverse: 0;
  --chakra-space-y-reverse: 0;
  --chakra-colors-transparent: transparent;
  --chakra-colors-current: currentColor;
  --chakra-colors-black: #000000;
  --chakra-colors-white: #FFFFFF;
  --chakra-colors-whiteAlpha-50: rgba(255, 255, 255, 0.04);
  --chakra-colors-whiteAlpha-100: rgba(255, 255, 255, 0.06);
  --chakra-colors-whiteAlpha-200: rgba(255, 255, 255, 0.08);
  --chakra-colors-whiteAlpha-300: rgba(255, 255, 255, 0.16);
  --chakra-colors-whiteAlpha-400: rgba(255, 255, 255, 0.24);
  --chakra-colors-whiteAlpha-500: rgba(255, 255, 255, 0.36);
  --chakra-colors-whiteAlpha-600: rgba(255, 255, 255, 0.48);
  --chakra-colors-whiteAlpha-700: rgba(255, 255, 255, 0.64);
  --chakra-colors-whiteAlpha-800: rgba(255, 255, 255, 0.80);
  --chakra-colors-whiteAlpha-900: rgba(255, 255, 255, 0.92);
  --chakra-colors-blackAlpha-50: rgba(0, 0, 0, 0.04);
  --chakra-colors-blackAlpha-100: rgba(0, 0, 0, 0.06);
  --chakra-colors-blackAlpha-200: rgba(0, 0, 0, 0.08);
  --chakra-colors-blackAlpha-300: rgba(0, 0, 0, 0.16);
  --chakra-colors-blackAlpha-400: rgba(0, 0, 0, 0.24);
  --chakra-colors-blackAlpha-500: rgba(0, 0, 0, 0.36);
  --chakra-colors-blackAlpha-600: rgba(0, 0, 0, 0.48);
  --chakra-colors-blackAlpha-700: rgba(0, 0, 0, 0.64);
  --chakra-colors-blackAlpha-800: rgba(0, 0, 0, 0.80);
  --chakra-colors-blackAlpha-900: rgba(0, 0, 0, 0.92);
  --chakra-colors-gray-25: #DFE1DF;
  --chakra-colors-gray-40: #5B6670;
  --chakra-colors-gray-50: #DFE1DF33;
  --chakra-colors-gray-57: #8C9296;
  --chakra-colors-gray-100: #EDF2F7;
  --chakra-colors-gray-200: #E2E8F0;
  --chakra-colors-gray-300: #CBD5E0;
  --chakra-colors-gray-400: #A0AEC0;
  --chakra-colors-gray-500: #718096;
  --chakra-colors-gray-600: #4A5568;
  --chakra-colors-gray-700: #2D3748;
  --chakra-colors-gray-800: #1A202C;
  --chakra-colors-gray-900: #171923;
  --chakra-colors-red-50: #FFF5F5;
  --chakra-colors-red-100: #FCD2BF;
  --chakra-colors-red-200: #ED4337;
  --chakra-colors-red-300: #FC8181;
  --chakra-colors-red-400: #F56565;
  --chakra-colors-red-500: #E53E3E;
  --chakra-colors-red-600: #C53030;
  --chakra-colors-red-700: #9B2C2C;
  --chakra-colors-red-800: #822727;
  --chakra-colors-red-900: #63171B;
  --chakra-colors-orange-50: #FFFAF0;
  --chakra-colors-orange-100: #FEEBC8;
  --chakra-colors-orange-200: #FBD38D;
  --chakra-colors-orange-300: #F6AD55;
  --chakra-colors-orange-400: #ED8936;
  --chakra-colors-orange-500: #DD6B20;
  --chakra-colors-orange-600: #C05621;
  --chakra-colors-orange-700: #9C4221;
  --chakra-colors-orange-800: #7B341E;
  --chakra-colors-orange-900: #652B19;
  --chakra-colors-yellow-50: #FFFFF0;
  --chakra-colors-yellow-100: #FEFCBF;
  --chakra-colors-yellow-200: #fff68D;
  --chakra-colors-yellow-300: #F6E05E;
  --chakra-colors-yellow-400: #ECC94B;
  --chakra-colors-yellow-500: #D69E2E;
  --chakra-colors-yellow-600: #B7791F;
  --chakra-colors-yellow-700: #975A16;
  --chakra-colors-yellow-800: #744210;
  --chakra-colors-yellow-900: #5F370E;
  --chakra-colors-green-50: #F0FFF4;
  --chakra-colors-green-100: #C6F6D5;
  --chakra-colors-green-200: #9AE6B4;
  --chakra-colors-green-300: #68D391;
  --chakra-colors-green-400: #48BB78;
  --chakra-colors-green-500: #38A169;
  --chakra-colors-green-600: #2F855A;
  --chakra-colors-green-700: #276749;
  --chakra-colors-green-800: #22543D;
  --chakra-colors-green-900: #1C4532;
  --chakra-colors-teal-50: #E6FFFA;
  --chakra-colors-teal-100: #B9E1D6;
  --chakra-colors-teal-200: #81E6D9;
  --chakra-colors-teal-300: #4FD1C5;
  --chakra-colors-teal-400: #38B2AC;
  --chakra-colors-teal-500: #319795;
  --chakra-colors-teal-600: #2C7A7B;
  --chakra-colors-teal-700: #285E61;
  --chakra-colors-teal-800: #234E52;
  --chakra-colors-teal-900: #1D4044;
  --chakra-colors-blue-50: #ebf8ff;
  --chakra-colors-blue-97: #F3F7FE;
  --chakra-colors-blue-100: #bee3f8;
  --chakra-colors-blue-200: #90cdf4;
  --chakra-colors-blue-300: #63b3ed;
  --chakra-colors-blue-400: #4299e1;
  --chakra-colors-blue-500: #3182ce;
  --chakra-colors-blue-600: #2b6cb0;
  --chakra-colors-blue-700: #2c5282;
  --chakra-colors-blue-800: #2a4365;
  --chakra-colors-blue-900: #1A365D;
  --chakra-colors-cyan-50: #EDFDFD;
  --chakra-colors-cyan-100: #C4F1F9;
  --chakra-colors-cyan-200: #9DECF9;
  --chakra-colors-cyan-300: #76E4F7;
  --chakra-colors-cyan-400: #0BC5EA;
  --chakra-colors-cyan-500: #00B5D8;
  --chakra-colors-cyan-600: #00A3C4;
  --chakra-colors-cyan-700: #0987A0;
  --chakra-colors-cyan-800: #086F83;
  --chakra-colors-cyan-900: #065666;
  --chakra-colors-purple-50: #FAF5FF;
  --chakra-colors-purple-100: #E9D8FD;
  --chakra-colors-purple-200: #D6BCFA;
  --chakra-colors-purple-300: #B794F4;
  --chakra-colors-purple-400: #9F7AEA;
  --chakra-colors-purple-500: #805AD5;
  --chakra-colors-purple-600: #6B46C1;
  --chakra-colors-purple-700: #553C9A;
  --chakra-colors-purple-800: #44337A;
  --chakra-colors-purple-900: #322659;
  --chakra-colors-pink-50: #FFF5F7;
  --chakra-colors-pink-100: #FED7E2;
  --chakra-colors-pink-200: #FBB6CE;
  --chakra-colors-pink-300: #F687B3;
  --chakra-colors-pink-400: #ED64A6;
  --chakra-colors-pink-500: #D53F8C;
  --chakra-colors-pink-600: #B83280;
  --chakra-colors-pink-700: #97266D;
  --chakra-colors-pink-800: #702459;
  --chakra-colors-pink-900: #521B41;
  --chakra-colors-linkedin-50: #E8F4F9;
  --chakra-colors-linkedin-100: #CFEDFB;
  --chakra-colors-linkedin-200: #9BDAF3;
  --chakra-colors-linkedin-300: #68C7EC;
  --chakra-colors-linkedin-400: #34B3E4;
  --chakra-colors-linkedin-500: #00A0DC;
  --chakra-colors-linkedin-600: #008CC9;
  --chakra-colors-linkedin-700: #0077B5;
  --chakra-colors-linkedin-800: #005E93;
  --chakra-colors-linkedin-900: #004471;
  --chakra-colors-facebook-50: #E8F4F9;
  --chakra-colors-facebook-100: #D9DEE9;
  --chakra-colors-facebook-200: #B7C2DA;
  --chakra-colors-facebook-300: #6482C0;
  --chakra-colors-facebook-400: #4267B2;
  --chakra-colors-facebook-500: #385898;
  --chakra-colors-facebook-600: #314E89;
  --chakra-colors-facebook-700: #29487D;
  --chakra-colors-facebook-800: #223B67;
  --chakra-colors-facebook-900: #1E355B;
  --chakra-colors-messenger-50: #D0E6FF;
  --chakra-colors-messenger-100: #B9DAFF;
  --chakra-colors-messenger-200: #A2CDFF;
  --chakra-colors-messenger-300: #7AB8FF;
  --chakra-colors-messenger-400: #2E90FF;
  --chakra-colors-messenger-500: #0078FF;
  --chakra-colors-messenger-600: #0063D1;
  --chakra-colors-messenger-700: #0052AC;
  --chakra-colors-messenger-800: #003C7E;
  --chakra-colors-messenger-900: #002C5C;
  --chakra-colors-whatsapp-50: #dffeec;
  --chakra-colors-whatsapp-100: #b9f5d0;
  --chakra-colors-whatsapp-200: #90edb3;
  --chakra-colors-whatsapp-300: #65e495;
  --chakra-colors-whatsapp-400: #3cdd78;
  --chakra-colors-whatsapp-500: #22c35e;
  --chakra-colors-whatsapp-600: #179848;
  --chakra-colors-whatsapp-700: #0c6c33;
  --chakra-colors-whatsapp-800: #01421c;
  --chakra-colors-whatsapp-900: #001803;
  --chakra-colors-twitter-50: #E5F4FD;
  --chakra-colors-twitter-100: #C8E9FB;
  --chakra-colors-twitter-200: #A8DCFA;
  --chakra-colors-twitter-300: #83CDF7;
  --chakra-colors-twitter-400: #57BBF5;
  --chakra-colors-twitter-500: #1DA1F2;
  --chakra-colors-twitter-600: #1A94DA;
  --chakra-colors-twitter-700: #1681BF;
  --chakra-colors-twitter-800: #136B9E;
  --chakra-colors-twitter-900: #0D4D71;
  --chakra-colors-telegram-50: #E3F2F9;
  --chakra-colors-telegram-100: #C5E4F3;
  --chakra-colors-telegram-200: #A2D4EC;
  --chakra-colors-telegram-300: #7AC1E4;
  --chakra-colors-telegram-400: #47A9DA;
  --chakra-colors-telegram-500: #0088CC;
  --chakra-colors-telegram-600: #007AB8;
  --chakra-colors-telegram-700: #006BA1;
  --chakra-colors-telegram-800: #005885;
  --chakra-colors-telegram-900: #003F5E;
  --chakra-borders-none: 0;
  --chakra-borders-1px: 1px solid;
  --chakra-borders-2px: 2px solid;
  --chakra-borders-4px: 4px solid;
  --chakra-borders-8px: 8px solid;
  --chakra-fonts-heading: Nunito Sans;
  --chakra-fonts-body: Nunito Sans;
  --chakra-fonts-mono: Nunito Sans;
  --chakra-fontSizes-xs: 0.75rem;
  --chakra-fontSizes-sm: 0.875rem;
  --chakra-fontSizes-md: 1rem;
  --chakra-fontSizes-lg: 1.125rem;
  --chakra-fontSizes-xl: 1.25rem;
  --chakra-fontSizes-2xl: 1.5rem;
  --chakra-fontSizes-3xl: 1.875rem;
  --chakra-fontSizes-4xl: 2.25rem;
  --chakra-fontSizes-5xl: 3rem;
  --chakra-fontSizes-6xl: 3.75rem;
  --chakra-fontSizes-7xl: 4.5rem;
  --chakra-fontSizes-8xl: 6rem;
  --chakra-fontSizes-9xl: 8rem;
  --chakra-fontSizes-xxs: 0.625em;
  --chakra-fontWeights-hairline: 100;
  --chakra-fontWeights-thin: 200;
  --chakra-fontWeights-light: 300;
  --chakra-fontWeights-normal: 400;
  --chakra-fontWeights-medium: 500;
  --chakra-fontWeights-semibold: 600;
  --chakra-fontWeights-bold: 700;
  --chakra-fontWeights-extrabold: 800;
  --chakra-fontWeights-black: 900;
  --chakra-letterSpacings-tighter: -0.05em;
  --chakra-letterSpacings-tight: -0.025em;
  --chakra-letterSpacings-normal: 0;
  --chakra-letterSpacings-wide: 0.025em;
  --chakra-letterSpacings-wider: 0.05em;
  --chakra-letterSpacings-widest: 0.1em;
  --chakra-lineHeights-3: .75rem;
  --chakra-lineHeights-4: 1rem;
  --chakra-lineHeights-5: 1.25rem;
  --chakra-lineHeights-6: 1.5rem;
  --chakra-lineHeights-7: 1.75rem;
  --chakra-lineHeights-8: 2rem;
  --chakra-lineHeights-9: 2.25rem;
  --chakra-lineHeights-10: 2.5rem;
  --chakra-lineHeights-normal: normal;
  --chakra-lineHeights-none: 1;
  --chakra-lineHeights-shorter: 1.25;
  --chakra-lineHeights-short: 1.375;
  --chakra-lineHeights-base: 1.5;
  --chakra-lineHeights-tall: 1.625;
  --chakra-lineHeights-taller: 2;
  --chakra-radii-none: 0;
  --chakra-radii-sm: 0.125rem;
  --chakra-radii-base: 0.25rem;
  --chakra-radii-md: 0.375rem;
  --chakra-radii-lg: 0.5rem;
  --chakra-radii-xl: 0.75rem;
  --chakra-radii-2xl: 1rem;
  --chakra-radii-3xl: 1.5rem;
  --chakra-radii-full: 9999px;
  --chakra-space-1: 0.25rem;
  --chakra-space-2: 0.5rem;
  --chakra-space-3: 0.75rem;
  --chakra-space-4: 1rem;
  --chakra-space-5: 1.25rem;
  --chakra-space-6: 1.5rem;
  --chakra-space-7: 1.75rem;
  --chakra-space-8: 2rem;
  --chakra-space-9: 2.25rem;
  --chakra-space-10: 2.5rem;
  --chakra-space-12: 3rem;
  --chakra-space-14: 3.5rem;
  --chakra-space-16: 4rem;
  --chakra-space-20: 5rem;
  --chakra-space-24: 6rem;
  --chakra-space-28: 7rem;
  --chakra-space-32: 8rem;
  --chakra-space-36: 9rem;
  --chakra-space-40: 10rem;
  --chakra-space-44: 11rem;
  --chakra-space-48: 12rem;
  --chakra-space-52: 13rem;
  --chakra-space-56: 14rem;
  --chakra-space-60: 15rem;
  --chakra-space-64: 16rem;
  --chakra-space-72: 18rem;
  --chakra-space-80: 20rem;
  --chakra-space-96: 24rem;
  --chakra-space-px: 1px;
  --chakra-space-0-5: 0.125rem;
  --chakra-space-1-5: 0.375rem;
  --chakra-space-2-5: 0.625rem;
  --chakra-space-3-5: 0.875rem;
  --chakra-shadows-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --chakra-shadows-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --chakra-shadows-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --chakra-shadows-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --chakra-shadows-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --chakra-shadows-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --chakra-shadows-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --chakra-shadows-outline: 0 0 0 3px rgba(66, 153, 225, 0.6);
  --chakra-shadows-inner: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  --chakra-shadows-none: none;
  --chakra-shadows-dark-lg: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px;
  --chakra-sizes-1: 0.25rem;
  --chakra-sizes-2: 0.5rem;
  --chakra-sizes-3: 0.75rem;
  --chakra-sizes-4: 1rem;
  --chakra-sizes-5: 1.25rem;
  --chakra-sizes-6: 1.5rem;
  --chakra-sizes-7: 1.75rem;
  --chakra-sizes-8: 2rem;
  --chakra-sizes-9: 2.25rem;
  --chakra-sizes-10: 2.5rem;
  --chakra-sizes-12: 3rem;
  --chakra-sizes-14: 3.5rem;
  --chakra-sizes-16: 4rem;
  --chakra-sizes-20: 5rem;
  --chakra-sizes-24: 6rem;
  --chakra-sizes-28: 7rem;
  --chakra-sizes-32: 8rem;
  --chakra-sizes-36: 9rem;
  --chakra-sizes-40: 10rem;
  --chakra-sizes-44: 11rem;
  --chakra-sizes-48: 12rem;
  --chakra-sizes-52: 13rem;
  --chakra-sizes-56: 14rem;
  --chakra-sizes-60: 15rem;
  --chakra-sizes-64: 16rem;
  --chakra-sizes-72: 18rem;
  --chakra-sizes-80: 20rem;
  --chakra-sizes-96: 24rem;
  --chakra-sizes-px: 1px;
  --chakra-sizes-0-5: 0.125rem;
  --chakra-sizes-1-5: 0.375rem;
  --chakra-sizes-2-5: 0.625rem;
  --chakra-sizes-3-5: 0.875rem;
  --chakra-sizes-max: max-content;
  --chakra-sizes-min: min-content;
  --chakra-sizes-full: 100%;
  --chakra-sizes-3xs: 14rem;
  --chakra-sizes-2xs: 16rem;
  --chakra-sizes-xs: 20rem;
  --chakra-sizes-sm: 24rem;
  --chakra-sizes-md: 28rem;
  --chakra-sizes-lg: 32rem;
  --chakra-sizes-xl: 36rem;
  --chakra-sizes-2xl: 42rem;
  --chakra-sizes-3xl: 48rem;
  --chakra-sizes-4xl: 56rem;
  --chakra-sizes-5xl: 64rem;
  --chakra-sizes-6xl: 72rem;
  --chakra-sizes-7xl: 80rem;
  --chakra-sizes-8xl: 90rem;
  --chakra-sizes-container-sm: 640px;
  --chakra-sizes-container-md: 768px;
  --chakra-sizes-container-lg: 1024px;
  --chakra-sizes-container-xl: 1280px;
  --chakra-zIndices-hide: -1;
  --chakra-zIndices-auto: auto;
  --chakra-zIndices-base: 0;
  --chakra-zIndices-docked: 10;
  --chakra-zIndices-dropdown: 1000;
  --chakra-zIndices-sticky: 1100;
  --chakra-zIndices-banner: 1200;
  --chakra-zIndices-overlay: 1300;
  --chakra-zIndices-modal: 1400;
  --chakra-zIndices-popover: 1500;
  --chakra-zIndices-skipLink: 1600;
  --chakra-zIndices-toast: 1700;
  --chakra-zIndices-tooltip: 1800;
  --chakra-transition-property-common: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  --chakra-transition-property-colors: background-color,border-color,color,fill,stroke;
  --chakra-transition-property-dimensions: width,height;
  --chakra-transition-property-position: left,right,top,bottom;
  --chakra-transition-property-background: background-color,background-image,background-position;
  --chakra-transition-easing-ease-in: cubic-bezier(0.4, 0, 1, 1);
  --chakra-transition-easing-ease-out: cubic-bezier(0, 0, 0.2, 1);
  --chakra-transition-easing-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --chakra-transition-duration-ultra-fast: 50ms;
  --chakra-transition-duration-faster: 100ms;
  --chakra-transition-duration-fast: 150ms;
  --chakra-transition-duration-normal: 200ms;
  --chakra-transition-duration-slow: 300ms;
  --chakra-transition-duration-slower: 400ms;
  --chakra-transition-duration-ultra-slow: 500ms;
  --chakra-blur-none: 0;
  --chakra-blur-sm: 4px;
  --chakra-blur-base: 8px;
  --chakra-blur-md: 12px;
  --chakra-blur-lg: 16px;
  --chakra-blur-xl: 24px;
  --chakra-blur-2xl: 40px;
  --chakra-blur-3xl: 64px;
  
  --body-bold-font-family: "Nunito Sans", sans-serif;
  --body-bold-font-size: 20px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 800;
  --body-bold-letter-spacing: 0px;
  --body-bold-line-height: normal;
  --body-font-family: "Nunito Sans",sans-serif;
  --body-font-size: 20px;
  --body-font-style: normal;
  --body-font-weight: 400;
  --body-letter-spacing: 0px;
  --body-line-height: normal;
  --body-small-font-family: "Nunito Sans",sans-serif;
  --body-small-font-size: 18px;
  --body-small-font-style: normal;
  --body-small-font-weight: 400;
  --body-small-letter-spacing: 0px;
  --body-small-line-height: normal;
  --h1-font-family: "Nunito Sans",sans-serif;
  --h1-font-size: 60px;
  --h1-font-style: normal;
  --h1-font-weight: 800;
  --h1-letter-spacing: 0px;
  --h1-line-height: normal;
  --h2-bold-font-family: "Nunito Sans",sans-serif;
  --h2-bold-font-size: 28px;
  --h2-bold-font-style: normal;
  --h2-bold-font-weight: 800;
  --h2-bold-letter-spacing: 0px;
  --h2-bold-line-height: normal;
  --h2-font-family: "Nunito Sans",sans-serif;
  --h2-font-size: 28px;
  --h2-font-style: normal;
  --h2-font-weight: 400;
  --h2-letter-spacing: 0px;
  --h2-line-height: normal
}
.landing-page-for div {
  box-sizing: border-box;}
.landing-page-for {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin:0 auto;
}

.landing-page-for .div {
  position: relative;
  width:100%;
}

.landing-page-for .footer {    
  background: var(--chakra-colors-yellow-200);
  position: relative;
  width: 100%;
  padding-top:50px;
}

.max-width-wrapper{
  width:100%; max-width: 1450px; margin:0 auto;
  padding:20px;
  position: relative;
}
.landing-page-for .footer-content {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 20px 20px;
  padding: 0px 0px 40px 220px;
  position: relative;
}
.landing-page-for .footer-cloud  {
  width: 260px;
  height: 120px;
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  vertical-align: middle;
  fill: var(--chakra-colors-white);
  position: absolute;
  bottom: -1px;
  left: 0px;
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.landing-page-for .chakra-link {
  text-decoration-line: underline;
}
.landing-page-for .footer .footer-logo{position: relative;}
.landing-page-for .footer .footer-logo>svg {
  width: 130px;
  height: 60px;
  display: inline-block;
  line-height: 1em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: currentColor;
  vertical-align: middle;
  position: absolute;
  left: var(--chakra-space-2);
  bottom: var(--chakra-space-2);
}
.landing-page-for .want-more {
  color: #000000;
  font-family: var(--h2-bold-font-family);
  font-size: var(--h2-bold-font-size);
  font-style: var(--h2-bold-font-style);
  font-weight: var(--h2-bold-font-weight);
  letter-spacing: var(--h2-bold-letter-spacing);
  line-height: var(--h2-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.landing-page-for .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.landing-page-for .text-wrapper {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page-for .text-wrapper-2 {
  color: #000000;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page-for .header-contact-us {
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  position: relative;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  vertical-align: center;
}

.landing-page-for .ag-logo {
  position: relative;
  width: 403px;
  height: 190px;
}

.landing-page-for .flex-expander {
  flex: auto;
  padding: 0;
  margin: 0;
  position: relative;
}

.landing-page-for .phone-button {
  width: fit-content;
  position: relative;
}

.landing-page-for .contact-us {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 330px;
}

.landing-page-for .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.landing-page-for  p{ margin:0}
.landing-page-for .vector {
  height: 14.67px;
  position: relative;
  width: 18.33px;
}

.landing-page-for .hello-auntygrace-com {
  background: transparent;
  border: none;
  color: #000000;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  margin-top: -1px;
  padding: 0;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page-for .img {
  height: 22px;
  position: relative;
  width: 22px;
}

.landing-page-for .text-wrapper-3 {
  color: #000000;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing-page-for .p {
  color: #000000;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  margin-top: -1px;
  position: relative;
  width: 100%;
}


.landing-page-for .our-services {
  align-items: flex-start;
  background-color: #b9e1d6;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding:80px;
}

.landing-page-for .div-4 {
  display: block;
  gap: 20px;
  position: relative;
  width: 100%;
}

.landing-page-for .section-title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.landing-page-for .div-5 {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin: -1px 0 0 0;
  position: relative;
  width: fit-content;

}

.landing-page-for .span {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  margin:0;
}

.landing-page-for .text-wrapper-4 {
  font-weight: 800;
}


.landing-page-for .cards-scroll .card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 305px;
  height: 520px;
}
.landing-page-for .rectangle-card {    
  background: var(--chakra-colors-white);
  border-radius: 8px;
  height: 500px;
  width: 280px;
  position: relative;
}

.landing-page-for .text-content {
  box-sizing: border-box;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  position: relative;
  width: 100%;
  line-height: var(--body-line-height);
}


.landing-page-for .icon-and-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.landing-page-for .img-2 {
  height: 32px;
  position: relative;
  width: 32px;
}

.landing-page-for .service-name {
  color: #000000;
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  position: relative;
  width: fit-content;
}

.landing-page-for .description {
  align-self: stretch;
  color: #000000;
  font-family: var(--body-font-family);
  font-size: 18px;
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  padding:0px;
  margin:0;
}

.landing-page-for .doctor-female {
  height: 28px;
  position: relative;
  width: 28px;
}

.landing-page-for .service-name-2 {
  color: #000000;
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  margin-top: -0.5px;
  position: relative;
  width: fit-content;
}

.landing-page-for .description-2 {
  align-self: stretch;
  color: #000000;
  font-family: "Nunito Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.landing-page-for .hear-from-other {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 608px;
  left: 0;
  padding: 80px;
  position: absolute;
  top: 2096px;
  width: 1440px;
}

.landing-page-for .text-wrapper-5 {
  color: #000000;
  font-family: var(--h2-font-family);
  font-size: var(--h2-font-size);
  font-style: var(--h2-font-style);
  font-weight: var(--h2-font-weight);
  letter-spacing: var(--h2-letter-spacing);
  line-height: var(--h2-line-height);
}

.landing-page-for .square-card {
  box-shadow: var(--chakra-shadows-dark-lg);
  background: var(--chakra-colors-white);
  border-radius: 8px;
  height: 268px;
  width: 240px;
  
}

.landing-page-for .img-3 {
  height: 110px;
  object-fit: cover;
  position: relative;
  width: 110px;
}

.landing-page-for .text-content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 10px 40px;
  position: relative;
  width: 100%;
}

.landing-page-for .name-and-age {
  color: #000000;
  font-family: "Nunito Sans", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.landing-page-for .text-wrapper-6 {
  color: #000000;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
}

.landing-page-for .text-wrapper-7 {
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
}

.landing-page-for .description-3 {
  align-self: stretch;
  color: #000000;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  position: relative;
  text-align: center;
}

.landing-page-for .why-auntie-grace {
  align-items: flex-start;
  background-color: #fff68d;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding:80px;
}
.landing-page-for .why-auntie-grace-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  max-width: 100%;
}

.landing-page-for .text-wrapper-8 {
  font-family: var(--h2-bold-font-family);
  font-size: var(--h2-bold-font-size);
  font-style: var(--h2-bold-font-style);
  font-weight: var(--h2-bold-font-weight);
  letter-spacing: var(--h2-bold-letter-spacing);
  line-height: var(--h2-bold-line-height);
}

.landing-page-for .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 120px;
  max-width: 100%;
  position: relative;
}

.landing-page-for .text-wrapper-9 {
  align-self: stretch;
  color: #000000;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-for .info {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 40px 0px 40px 120px;
  position: relative;
  flex-wrap: nowrap;
}

.landing-page-for .info .icon {
  flex: 0 0 auto;
}
.landing-page-for .info .content {
  flex: 1 1 auto;
}

.landing-page-for .icon {
  background-color: #ffffff;
  border-radius: 42.5px;
  height: 85px;
  position: relative;
  width: 85px;
}

.landing-page-for .trust {
  height: 50px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 50px;
}

.landing-page-for .content {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 0px 0px 10px;
  position: relative;
}

.landing-page-for .description-4 {
  color: #000000;
  font-family: "Nunito Sans", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 939px;
}

.landing-page-for .icons-doctor-female {
  height: 42px;
  left: 21px;
  position: absolute;
  top: 22px;
  width: 42px;
}

.landing-page-for .group {
  height: 43px;
  left: 16px;
  position: absolute;
  top: 21px;
  width: 53px;
}

.landing-page-for .cloud {
  height: 323px;
  left: 0;
  position: absolute;
  top: 749px;
  width: 712px;
}

.landing-page-for .title-description {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px 52% 10px 10px;
  position: relative;
}

.landing-page-for .title {
  align-self: stretch;
  color: #000000;
  font-family: var(--h1-font-family);
  font-size: var(--h1-font-size);
  font-style: var(--h1-font-style);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-for .description-5 {
  align-self: stretch;
  color: #000000;
  font-family: "Nunito Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.landing-page-for .form {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  padding: 10px;
  position: relative;
  max-width: 604px;
  width:100%;
}

.landing-page-for .input-field::placeholder {
  color: #000; 
}
.landing-page-for .input-field {
  background-color: #fff;
  color: #000;
  font-family: var(--body-small-font-family);
  font-size: 16px;
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  border:1px solid #000;
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  padding: 0; 
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  padding-left:1em;
}
.landing-page-for .overlap-wrapper {
  height: 50px;
  margin-right: -2px;
  position: relative;
  width: 282px;
}

.landing-page-for .overlap {
  background-color: #f3f7fe;
  height: 50px;
  position: relative;
  width: 280px;
}

.landing-page-for .text-wrapper-10 {
  color: #8c9296;
  font-family: var(--body-small-font-family);
  font-size: var(--body-small-font-size);
  font-style: var(--body-small-font-style);
  font-weight: var(--body-small-font-weight);
  left: 16px;
  letter-spacing: var(--body-small-letter-spacing);
  line-height: var(--body-small-line-height);
  position: absolute;
  top: 13px;
}

.landing-page-for .submit {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  padding: 10px;
  position: relative;
}

.landing-page-for .consent {
  color: #000000;
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.landing-page-for .submit-button {
  all: unset;
  align-items: flex-start;
  align-self: stretch;
  background-color: #000000;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 47px;
  padding: 10px 106px;
  position: relative;
  width: 100%;
}

.landing-page-for .text-wrapper-11 {
  align-self: stretch;
  color: #ffffff;
  flex: 1;
  font-family: "Nunito Sans", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}


.landing-page-for .header-and-form {
  background-color: #ffffff;
  display: block;  
  width: 100%;
  position: relative;
}

.landing-page-for .header-cloud {
  right:0; bottom:0;
  
  position: absolute;
  
  width: 380px;
}

.landing-page-for .header-image {
  display: block;
  right: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  width:48%;
}

.landing-page-for .table-of-people {
  height: auto;
  margin-top: -0.75px;
  position: relative;
  width: 100%;
}

.landing-page-for option:disabled {
  color: -internal-light-dark(graytext, #8c9296);
}

a:webkit-any-link {
  color: #000;
  text-decoration: none;
}
.card ul {
  display: block;
  font-size:18px;
  list-style-type: disc;
  margin-block-start:20px;
  margin-block-end: 20px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.cards-scroll {
  display: block;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
  -webkit-margin-end: auto;
  margin-inline-end: auto;
  max-width: 100vw;
}
.cards-scroll .slick-dots li button:before {
  font-size: 20px!important;
}
.formSuccessMessage {min-height:280px;}
@media screen and (min-width: 80em){
  .landing-page-for .footer-cloud  {
    width: 370px;
    height: 170px;
  }
  .landing-page-for .footer .footer-logo>svg {
    height: 80px;
    width: 180px;
  }
}

@media screen and (min-width: 48em){
  .cards-scroll { max-width:1220px;}
  ul {
    font-size: var(--chakra-fontSizes-xl);
    margin-left: var(--chakra-space-8);
  }
  .landing-page-for .footer-cloud  {
    bottom:-1px;
  }
  .landing-page-for .footer .footer-logo>svg {
    left: var(--chakra-space-5);
    bottom: var(--chakra-space-5);
  }
}

@media screen and (max-width: 640px){
  .landing-page-for .div-2,.landing-page-for .want-more {padding-bottom: 20px;}
  .landing-page-for .footer-content {
    display: block;
    padding: 0px 0px 80px 80px;
    position: relative;
  }
  .landing-page-for .title {
    font-size:28px;
  }
  .landing-page-for .header-cloud {
    bottom: -30px;
  }
  .landing-page-for .why-auntie-grace{padding:20px;}
  .landing-page-for .overlap-wrapper,.landing-page-for .overlap,.landing-page-for .overlap-group-wrapper{width:100%;}
}
@media screen and (max-width: 800px){
  .landing-page-for .title {
    font-size:36px;
  }
  .landing-page-for .info {padding-left: 30px;} 
  .landing-page-for .title-description {
    padding: 10px 10% 10px 10px;
  }
  .landing-page-for .title {
    flex:0 0 100%;}
    .landing-page-for .header-cloud{width:220px;}
  .landing-page-for .header-image {
    position: relative;
    width: 100%; 
    margin:0 auto;
    flex:0 0 100%;
    max-width: 480px;;
}
}

@media screen and (max-width: 640px){
  .landing-page-for .our-services{padding:20px;}
}
@media screen and (max-width: 480px){
  .landing-page-for .our-services{padding:20px 20px 40px 20px;}
  .landing-page-for .want-more{ font-size:24px;}
  .landing-page-for .info {
    align-items: flex-start;
    padding: 10px 0px 10px 0px;
  }
  .landing-page-for .div-wrapper {padding-left:20px;}
  .max-width-wrapper{padding:20px 20px 40px;}
  .landing-page-for .title {
    font-size:32px;
  }
  .landing-page-for .header-cloud {
    bottom: -50px;
  }
  .landing-page-for .icon {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .landing-page-for .icons-doctor-female {
    height: 30px;
    left: 12px;
    top: 12px;
    width: 30px;
  }
  .landing-page-for .trust {
    height: 30px;
    left: 10px;
    top: 10px;
    width: 30px;
  }
  .landing-page-for .group {
    height: auto;
    left: 13px;
    position: absolute;
    top: 15px;
    width: 30px;
  }
  .landing-page-for .footer-content {
    display: block;
    padding: 0px 0px 120px 30px;
    position: relative;
  }
  
  .landing-page-for .contact-us  { width:100%;}
}


@media screen and (max-width: 360px){
  .max-width-wrapper{ padding: 20px 0 40px 0;}
  .landing-page-for .rectangle-card {width:260px;}
}